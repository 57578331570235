import React, { useState } from "react";
import Cycle from "../Contexts/Cycle";
import axios from "axios";

export const CycleState = (props) => {
  const [Cycles, setCycle] = useState([]);
  const baseUrl = "";

  //Add Cycles
  // const AddCycle = async (formData) => {
  //   // API Call
  //   const response = await fetch(`${baseUrl}cycles`, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": false,
  //     },
  //     processData: false,
  //     body: formData,
  //   });
  // };

  //Fetch Cycles
  const fetchCycle = async () => {
    try {
      const response = await axios.get(`${baseUrl}get-cycles/${localStorage.getItem('id')}/${localStorage.getItem('project_id')}`);
      const data = await response.data;
      const CycleArray = Object.values(data.CycleData);
      const flattenedArray = CycleArray.flat();
      setCycle(flattenedArray);
      // Do something with the data
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  };

  return (
    <Cycle.Provider value={{ Cycles, fetchCycle}}>
      {props.children}
    </Cycle.Provider>
  );
};
