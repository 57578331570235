import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const MainDashBoard = lazy(() => import("../views/ui/Top-Nav/Dashboard"));
const StudyNotification = lazy(() =>
  import("../views/ui/Top-Nav/StudyNotification")
);
const NotificationRegistration = lazy(() =>
  import("../views/ui/Top-Nav/NotificationsRegister")
);
const Guidelines = lazy(() => import("../views/ui/Top-Nav/Guidelines"));
const GuidelineRegistration = lazy(() =>
  import("../views/ui/Top-Nav/GuidelineRegister")
);
const AlarmLog = lazy(() => import("../views/ui/Top-Nav/AlarmLog"));

const Calendar = lazy(() => import("../views/ui/Top-Nav/Calendar"));
const MilestoneIndex = lazy(() =>
  import("../views/ui/Top-Nav/Milestone/Index.js")
);
const Milestone = lazy(() =>
  import("../views/ui/Top-Nav/Milestone/Milestone.js")
);
const ScopeofWork = lazy(() =>
  import("../views/ui/Top-Nav/Milestone/SOW.js")
);
const AddScopeofWork = lazy(() =>
  import("../views/ui/Top-Nav/Milestone/Add_SOW.js")
);
const Tasks = lazy(() =>
  import("../views/ui/Top-Nav/Milestone/TaskList.js")
);
const About = lazy(() => import("../views/About.js"));
const BuildSetting = lazy(() => import("../views/ui/build/BuildSetting"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const DataPage = lazy(() => import("../views/ui/DataPage"));
const Projects = lazy(() => import("../views/ui/Projects/Projects"));
const ProjectSubject = lazy(() => import("../views/ui/Projects/Subjects.js"));
const ProjectCRF = lazy(() => import("../views/ui/Projects/CRF/Index"));
const Subjects = lazy(() => import("../views/ui/Subjects/Subject"));
const AddSubject = lazy(() => import("../views/ui/Subjects/Add"));
const CRFAns = lazy(() => import("../views/ui/Subjects/CRFAns.js"));
const CRFAnsQuery = lazy(() => import("../views/ui/Subjects/Quries.js"));

const BuildPreview = lazy(() =>
  import("../views/ui/build/CRF-Accordion-Data/cycle-visit/Preview")
);
const Site = lazy(() => import("../views/ui/site/Site"));
const Site_List = lazy(() => import("../views/ui/site/sites/List"));
const IP_Drug = lazy(() => import("../views/ui/site/ip-drug/Index"));
const IP_Drug_List = lazy(() => import("../views/ui/site/ip-drug/List"));
const Site_Edit = lazy(() => import("../views/ui/site/sites/Edit"));
const Drug_Edit = lazy(() => import("../views/ui/site/ip-drug/Add"));
const Drug_Request = lazy(() => import("../views/ui/site/ip-drug/RequestList"));
const Drug_Return = lazy(() => import("../views/ui/site/ip-drug/Returns"));
const Drug_Return_Add = lazy(() =>
  import("../views/ui/site/ip-drug/AddReturns")
);
const Drug_Requests = lazy(() => import("../views/ui/site/ip-drug/Requests"));
const SampleIndex = lazy(() => import("../views/ui/site/sample/Index"));
const SampleList = lazy(() => import("../views/ui/site/sample/SampleList"));
const SampleRequest = lazy(() =>
  import("../views/ui/site/sample/PickUpRequest")
);
const UserIndex = lazy(() => import("../views/ui/site/user/Index"));
const UserAdd = lazy(() => import("../views/ui/site/user/AddUser"));
const Monitoring = lazy(() => import("../views/ui/Monitoring/Monitoring"));
const MonitorDashboard = lazy(() =>
  import("../views/ui/Monitoring/components/Dashboard")
);
const MonitorQuery = lazy(() =>
  import("../views/ui/Monitoring/components/Query")
);
const SAE = lazy(() => import("../views/ui/Monitoring/components/SAE_AE"));
const SAE_Report = lazy(() =>
  import("../views/ui/Monitoring/components/SAE_Report")
);
const MVR = lazy(() => import("../views/ui/Monitoring/components/MVR"));
const MVR_Report = lazy(() =>
  import("../views/ui/Monitoring/components/MVR_Report")
);
const Edit_MVR_Report = lazy(() =>
  import("../views/ui/Monitoring/components/EditMVR")
);
// reports pages
const Report = lazy(() => import("../views/ui/report/Index"));
const CRFStatus = lazy(() => import("../views/ui/report/CRFStatus"));
const SiteReport = lazy(() => import("../views/ui/report/SiteReport"));
const EnrollmentStatus = lazy(() =>
  import("../views/ui/report/EnrollmentStatus")
);
const SQAR = lazy(() => import("../views/ui/report/SQAR"));
const Performance_Report = lazy(() =>
  import("../views/ui/report/Performance_Report")
);
// reports pages end
const ProjectAdd = lazy(() => import("../views/ui/Projects/AddProject.js"));
const Roles = lazy(() => import("../views/ui/Projects/Roles.js"));
// summary pages

const SummaryIndex = lazy(() => import("../views/ui/summary/Index"));
const StudyOverview = lazy(() => import("../views/ui/summary/StudyOverview"));
const Comment = lazy(() => import("../views/ui/summary/Comment"));
const SiteStatus = lazy(() => import("../views/ui/summary/SiteStatus"));
const MonthlyEnrollment = lazy(() =>
  import("../views/ui/summary/MonthlyEnrollment")
);
const QueryStatus = lazy(() => import("../views/ui/summary/QueryStatus"));
const SubjectVisitStatus = lazy(() =>
  import("../views/ui/summary/SubjectVisitStatus")
);
// summary pages end

// build pages
const Build = lazy(() => import("../views/ui/build/Index"));
const Schedule = lazy(() => import("../views/ui/build/Schedule"));
const CycleVisit = lazy(() => import("../views/ui/build/CycleVisit"));
const CRF_Group = lazy(() => import("../views/ui/build/CRFGroup"));
const MainCRF = lazy(() => import("../views/ui/build/CRF"));
// build pages end

const PD = lazy(() => import("../views/ui/Monitoring/components/PD"));
const Results = lazy(() => import("../views/ui/Projects/Results"));
const Settings = lazy(() => import("../views/ui/Settings.js"));
const ReviewDetails = lazy(() => import("../views/ui/review/ReviewDetails"));
const ReviewDetail = lazy(() => import("../views/ui/review/ReviewDetail.js"));
const SubjectList = lazy(() => import("../views/ui/review/SubjectList.js"));
const SubjectListFreeze = lazy(() =>
  import("../views/ui/Data/SubjectListFreeze.js")
);
const SubjectListLock = lazy(() =>
  import("../views/ui/locking/softLocking/SubjectList")
);
const Reviews = lazy(() => import("../views/ui/review/Reviews"));
const Freezing = lazy(() => import("../views/ui/Data/Freezing"));
const Locking = lazy(() => import("../views/ui/locking/Locking"));
const DataSet = lazy(() => import("../views/ui/Data/DataSet/DataSet"));
const Document = lazy(() => import("../views/ui/Data/documents/Document"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/login", element: <Navigate to="/home" /> },
      { path: "/", element: <Navigate to="/home" /> },
      {
        path: "/home",
        exact: true,
        element: <Starter />,
        children: [
          // {
          //   path: "/home",
          //   exact: true,
          //   element: <Navigate to="/home/dashboard" />,
          // },
          { path: "/home/dashboard", exact: true, element: <MainDashBoard /> },
          {
            path: "/home/notifications",
            exact: true,
            element: <StudyNotification />,
          },
          {
            path: "/home/notifications/registration",
            exact: true,
            element: <NotificationRegistration />,
          },
          {
            path: "/home/notifications/edit/:notification_id",
            exact: true,
            element: <NotificationRegistration />,
          },
          { path: "/home/guidelines", exact: true, element: <Guidelines /> },
          {
            path: "/home/guidelines/registration",
            exact: true,
            element: <GuidelineRegistration />,
          },
          {
            path: "/home/guideline/edit/:guideline_id",
            exact: true,
            element: <GuidelineRegistration />,
          },
          { path: "/home/alarmlog", exact: true, element: <AlarmLog /> },
          { path: "/home/calendar", exact: true, element: <Calendar /> },
          {
            path: "/home/milestone",
            exact: true,
            element: <MilestoneIndex />,
            children: [
              {
                path: "/home/milestone",
                exact: true,
                element: <Navigate to={"/home/milestone/index"} />,
              },
              {
                path: "/home/milestone/index",
                exact: true,
                element: <Milestone />,
              },
              {
                path: "/home/milestone/sow",
                exact: true,
                element: <ScopeofWork />,
              },
              {
                path: "/home/milestone/add-sow/:unq_id",
                exact: true,
                element: <AddScopeofWork />,
              },
              {
                path: "/home/milestone/tasks",
                exact: true,
                element: <Tasks />,
              },
            ],
          },
        ],
      },
      { path: "/about", exact: true, element: <About /> },
      { path: "*", exact: true, element: <Navigate to="/" />  },

      {
        path: "/subject-review/:project_id/:subject_id",
        exact: true,
        element: <ReviewDetail />,
      },
      {
        path: "/subject-list/:project_id/:site_id",
        exact: true,
        element: <SubjectList />,
      },
      {
        path: "/subject-list-freeze/:project_id/:site_id",
        exact: true,
        element: <SubjectListFreeze />,
      },
      {
        path: "/subject-list-lock/:project_id/:site_id",
        exact: true,
        element: <SubjectListLock />,
      },

      { path: "/build-settings", exact: true, element: <BuildSetting /> },
      { path: "/settings", exact: true, element: <Settings /> },
      {
        path: "/data",
        exact: true,
        element: <DataPage />,
        children: [
          // { path: "/data", element: <Navigate to="/data/review" /> },
          {
            path: "/data/review-details",
            exact: true,
            element: <ReviewDetails />,
          },
          {
            path: "/data/review",
            exact: true,
            element: <Reviews />,
          },
          {
            path: "/data/freezing",
            exact: true,
            element: <Freezing />,
          },
          {
            path: "/data/subject-list-freeze/:project_id/:site_id",
            exact: true,
            element: <SubjectListFreeze />,
          },
          {
            path: "/data/locking",
            exact: true,
            element: <Locking />,
          },
          {
            path: "/data/subject-list-lock/:project_id/:site_id",
            exact: true,
            element: <SubjectListLock />,
          },
          {
            path: "/data/dataSet",
            exact: true,
            element: <DataSet />,
          },
          {
            path: "/data/document",
            exact: true,
            element: <Document />,
          },
          {
            path: "/data/subject-list/:project_id/:site_id",
            exact: true,
            element: <SubjectList />,
          },
        ],
      },
      // Report Routes
      {
        path: "/report",
        exact: true,
        element: <Report />,
        children: [
          // {
          //   path: "/report",
          //   exact: true,
          //   element: <Navigate to="/report/CRF-status" />,
          // },
          {
            path: "/report/CRF-status",
            exact: true,
            element: <CRFStatus />,
          },
          {
            path: "/report/site-report",
            exact: true,
            element: <SiteReport />,
          },
          {
            path: "/report/enrollment-status",
            exact: true,
            element: <EnrollmentStatus />,
          },
          {
            path: "/report/system-query",
            exact: true,
            element: <SQAR />,
          },
          {
            path: "/report/performance-report",
            exact: true,
            element: <Performance_Report />,
          },
        ],
      },
      {
        path: "/monitoring",
        exact: true,
        element: <Monitoring />,
        children: [
          {
            path: "/monitoring",
            element: <Navigate to="/monitoring/dashboard" />,
          },
          {
            path: "/monitoring/dashboard",
            exact: true,
            element: <MonitorDashboard />,
          },
          {
            path: "/monitoring/query",
            exact: true,
            element: <MonitorQuery />,
          },
          {
            path: "/monitoring/sae",
            exact: true,
            element: <SAE />,
          },
          {
            path: "/monitoring/sae_report/:subject_id/:site_id",
            exact: true,
            element: <SAE_Report />,
          },
          {
            path: "/monitoring/mvr",
            exact: true,
            element: <MVR />,
          },
          {
            path: "/monitoring/mvr_report",
            exact: true,
            element: <MVR_Report />,
          },
          {
            path: "/monitoring/edit-mvr/:id",
            exact: true,
            element: <Edit_MVR_Report />,
          },
          {
            path: "/monitoring/pd",
            exact: true,
            element: <PD />,
          },
        ],
      },
      // Summary Routes
      {
        path: "/summary",
        exact: true,
        element: <SummaryIndex />,
        children: [
          // {
          //   path: "/summary",
          //   element: <Navigate to="/summary/study-overview" />,
          // },
          {
            path: "/summary/study-overview",
            exact: true,
            element: <StudyOverview />,
          },
          {
            path: "/summary/comment",
            exact: true,
            element: <Comment />,
          },
          {
            path: "/summary/site-status",
            exact: true,
            element: <SiteStatus />,
          },
          {
            path: "/summary/monthly-enrollment",
            exact: true,
            element: <MonthlyEnrollment />,
          },
          {
            path: "/summary/query-status",
            exact: true,
            element: <QueryStatus />,
          },
          {
            path: "/summary/subject-status",
            exact: true,
            element: <SubjectVisitStatus />,
          },
        ],
      },
      // Build tab
      {
        path: "/build",
        exact: true,
        element: <Build />,
        children: [
          {
            path: "/build",
            element: <Navigate to="/build/schedule" />,
          },
          {
            path: "/build/schedule",
            exact: true,
            element: <Schedule />,
          },
          {
            path: "/build/cycle-visit",
            exact: true,
            element: <CycleVisit />,
          },
          {
            path: "/build/CRF-group",
            exact: true,
            element: <CRF_Group />,
          },
          {
            path: "/build/CRF-group",
            exact: true,
            element: <MonthlyEnrollment />,
          },
          {
            path: "/build/CRF",
            exact: true,
            element: <MainCRF />,
          },
        ],
      },
      {
        path: "/subject",
        exact: true,
        element: <Subjects />,
      },
      {
        path: "/add-subject",
        exact: true,
        element: <AddSubject />,
      },
      {
        path: "/crf-answer/:project_id/:subject_id/:group_id/:cycle_id/:visit_id/:status",
        exact: true,
        element: <CRFAns />,
      },
      {
        path: "/build-preview/:group_id",
        exact: true,
        element: <BuildPreview />,
      },
      {
        path: "/site",
        exact: true,
        element: <Site />,
        children: [
          // { path: "/site", element: <Navigate to="/site/list" /> },
          { path: "/site/list", exact: true, element: <Site_List /> },
          { path: "/site/add/", exact: true, element: <Site_Edit /> },
          { path: "/site/edit/:site_id", exact: true, element: <Site_Edit /> },
        ],
      },
      {
        path: "/ip-drug",
        exact: true,
        element: <Site />,
        children: [
          { path: "/ip-drug", element: <Navigate to="/ip-drug/list" /> },
          { path: "/ip-drug/stock/add", element: <Drug_Edit /> },
          { path: "/ip-drug/stock/update/:info_id", element: <Drug_Edit /> },
          {
            path: "/ip-drug/list",
            exact: true,
            element: <IP_Drug />,
            children: [
              {
                path: "/ip-drug/list",
                element: <Navigate to="/ip-drug/list/stock" />,
              },
              {
                path: "/ip-drug/list/stock",
                element: <IP_Drug_List />,
              },
              {
                path: "/ip-drug/list/request",
                element: <Drug_Request />,
              },
              {
                path: "/ip-drug/list/return",
                element: <Drug_Return />,
              },
              {
                path: "/ip-drug/list/requests",
                element: <Drug_Requests />,
              },
              {
                path: "/ip-drug/list/returns/add",
                element: <Drug_Return_Add />,
              },
            ],
          },
        ],
      },
      {
        path: "/sample",
        exact: true,
        element: <Site />,
        children: [
          { path: "/sample", element: <Navigate to="/sample/index" /> },
          {
            path: "/sample/index",
            exact: true,
            element: <SampleIndex />,
            children: [
              {
                path: "/sample/index",
                element: <Navigate to="/sample/index/list" />,
              },
              {
                path: "/sample/index/list",
                element: <SampleList />,
              },
              {
                path: "/sample/index/request",
                element: <SampleRequest />,
              },
              {
                path: "/sample/index/request/:id",
                element: <SampleRequest />,
              },
            ],
          },
        ],
      },
      {
        path: "/user",
        exact: true,
        element: <Site />,
        children: [
          { path: "/user", element: <Navigate to="/user/index" /> },
          {
            path: "/user/index",
            exact: true,
            element: <UserIndex />,
          },
          {
            path: "/user/add",
            exact: true,
            element: <UserAdd />,
          },
          {
            path: "/user/edit/:user_id",
            exact: true,
            element: <UserAdd />,
          },
        ],
      },
      { path: "/projects", exact: true, element: <Projects /> },
      { path: "/project-add", exact: true, element: <ProjectAdd /> },
      { path: "/roles", exact: true, element: <Roles /> },
      { path: "/project-subjects", exact: true, element: <ProjectSubject /> },
      { path: "/project/CRF", exact: true, element: <ProjectCRF /> },
      // { path: "/monitoring", exact: true, element: <Monitoring /> },
      { path: "/results", exact: true, element: <Results /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  },
];

export default ThemeRoutes;
