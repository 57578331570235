import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import './i18next';
import { CycleState } from "./context/States/Cycle";
import { VisitState } from "./context/States/Visit";
import { GlobalLoader } from './context/Contexts/GlobalLoader'

import { CRFGroupState } from "./context/States/CRFGroup";

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <GlobalLoader>
            <CycleState>
                <VisitState>
                    <CRFGroupState>
                        <Router>
                            <App />
                        </Router>
                    </CRFGroupState>
                </VisitState>
            </CycleState>
        </GlobalLoader>
    </Suspense>,

    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
