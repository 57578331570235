import React, { useState } from "react";
import Visit from "../Contexts/Visit";
import axios from "axios";

export const VisitState = (props) => {
  const [Visits, setVisits] = useState([])
  const baseUrl = "";

  // Add Visits
  const AddVisit = async (formData) => {
    // API Call
    const response = await axios.post(`${baseUrl}visits`, formData);
  };

  // Fetch Visit
  const FetchVists = async () => {
    const user_id = "2";
    try {
      const response = await axios.get(
        `${baseUrl}get-visits/${localStorage.getItem('id')}/${localStorage.getItem('project_id')}`
      );
      const data = await response.data;
      const CycleArray = Object.values(data.data);
      const flattenedArray = CycleArray.flat();
      setVisits(flattenedArray);
      // Do something with the data
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  };
  return <Visit.Provider value={{ Visits,AddVisit,FetchVists }}>{props.children}</Visit.Provider>;
};
