import { lazy } from "react";
import { Navigate } from "react-router-dom";
import ForgotPassword from "../views/ui/authentication/ForgotPassword";
import ResetPassword from "../views/ui/authentication/ResetPassword";
const Login = lazy(() => import("../views/ui/authentication/Login.js"));
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const FindId = lazy(() => import("../views/ui/authentication/FindID.js"));
const QRCode = lazy(() => import("../views/ui/qrform.js"));
const FindPassword = lazy(() =>
  import("../views/ui/authentication/FindPassword.js")
);
/*****Routes******/
const PublicRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      // { path: "*", element: <Navigate to="/" /> },
      { path: "/find-id", exact: true, element: <FindId /> },
      { path: "/find-password", exact: true, element: <FindPassword /> },
      { path: "/qr-form/:subject_id/:group_id/:cycle_id/:visit_id/:project_id", exact: true, element: <QRCode /> },
      { path: "/login", exact: true, element: <Login /> },
      { path: "/forgot-password", exact: true, element: <ForgotPassword /> },
      { path: "/reset/password/:token", exact: true, element: <ResetPassword /> }
    ],
  },
];

export default PublicRoutes;