import React, {useState,useEffect} from "react";
import CRFGroup from "../Contexts/CRFGroup";
import axios from "axios";
import { toast } from "react-hot-toast";

export const CRFGroupState = (props) => {


    const [crfGroups, setCrfGroups] = useState([]);
    const baseUrl = "";
    const PostCRFGroup = async (formData) => {
        // API Call
        try{
            const response = await axios.post(`${baseUrl}crf-group`, formData);
        }catch (e) {
            console.log(e)
        }

    };

    const GetCRFGroup = async () => {
        try {
            setCrfGroups([]);
            const response = await axios.get(
                `${baseUrl}get-crf-group/${localStorage.getItem('id')}/${localStorage.getItem('project_id')}`,
            );
            const CRGGroupArray = Object.values(response.data.data);
            const flattenedArray = CRGGroupArray.flat();
            // console.log(flattenedArray);
            setCrfGroups(flattenedArray);
            // Do something with the data
        } catch (error) {
            // Handle any errors
            console.log(error);
        }
    };

    const DeleteCRFGroup = async (id) => {
        // API Call
        var formdata = new FormData();
        formdata.append("group_id", id);
        const response = await axios.post(`${baseUrl}delete-group`, formdata);
        return response;
    };

    useEffect(() => {
        GetCRFGroup();
    }, []);

    return <CRFGroup.Provider
        value={{PostCRFGroup, GetCRFGroup, DeleteCRFGroup, crfGroups}}>{props.children}</CRFGroup.Provider>;
};