import React, { createContext, useState, useContext } from 'react';

const GlobalContext = createContext();

const GlobalLoader = ({ children }) => {
    const [globalState, setGlobalState] = useState(false);

    const globalFunction = () => {
        // Your global function logic here
    };

    return (
        <GlobalContext.Provider value={{ globalState, setGlobalState, globalFunction }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalLoader };
